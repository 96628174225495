import React from "react";
import styled from "styled-components";
import Link from "gatsby-link";

function NotFound() {
  return (
    <Wrapper>
      <Content>
        <Header>Looks like this page doesn't exist.</Header>
        <Button color="purple" to="/" style={{ background: "#635ec0" }}>
          Home
        </Button>
      </Content>
    </Wrapper>
  );
}

export default NotFound;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Header = styled.h1`
  font-size: clamp(4rem, 6vmin, 5rem);
  font-family: "Open Sans", sans-serif;
  font-weight: 200;
  line-height: 1.2;
  margin: 0.5rem 0;
`;

const Button = styled(Link)`
  margin: 2rem auto 0;
  width: 230px;
  font-size: 1.75rem;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
  letter-spacing: 1px;
  color: #fff;
  border: 1px solid #635ec0;
  background: ${(p) => {
    if (p.color === "pink") return p.theme.colors.brand.pink;
    if (p.color === "orange") return p.theme.colors.brand.orange;
    if (p.color === "blue") return p.theme.colors.brand.blue;
    if (p.color === "purple") return p.theme.colors.brand.purple;
    if (p.color === "lightPurple") return p.theme.colors.brand.lightPurple;
  }};
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;
